import {Invoice, Session} from '@emporos/api-enterprise';
import assert from 'assert';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import {InvoiceConsolidate} from '../api';
import {sessionLocaldb} from '../localDb/sessionLocaldb';
import {generateSessionKey} from '../utils/session';
import {
  useAuthentication,
  useTransactionsConfig,
  useNetworkAvailable,
} from './';

export type {Session, Invoice};

export type SessionUpdates =
  | Partial<Session>
  | ((prevSession: Session) => Partial<Session>);

export type InvoiceUpdates =
  | Partial<Invoice>
  | ((prevInvoice: Invoice) => Partial<Invoice>);
export interface Context {
  currentTransactionIndex?: number;
  session: Session;
  setSession: Dispatch<SetStateAction<Session>>;
  currentInvoiceId: string;
  setCurrentInvoiceId: Dispatch<SetStateAction<string>>;
  selectedPayment: string;
  setSelectedPayment: Dispatch<SetStateAction<string>>;
  savingSession: boolean;
}

const noop = () => undefined;

export const TransactionsContext = createContext<Context>({
  session: (null as unknown) as Session,
  setSession: noop,
  currentInvoiceId: '',
  setCurrentInvoiceId: noop,
  selectedPayment: '',
  setSelectedPayment: noop,
  savingSession: false,
});

export function TransactionsStateProvider(props: {
  children?: React.ReactNode;
}): JSX.Element {
  const {session, setSession} = useTransactionsConfig();
  const [currentInvoiceId, setCurrentInvoiceId] = useState('');
  const [selectedPayment, setSelectedPayment] = useState('');
  const [savingSession, setsavingSession] = useState(false);
  const [CompletingTransaction, setCompletingTransaction] = useState(false);
  const {user} = useAuthentication();
  const sessionKey = generateSessionKey(user);
  const online = useNetworkAvailable();

  const token = user ? user.access_token : '';
  const sessionId = session ? session.sessionId : '';
  const localSession = new sessionLocaldb(
    sessionKey,
    token,
    sessionId,
    currentInvoiceId,
  );

  assert(
    session !== null,
    'Internal Error: rendered session app tree without active session',
  );

  useEffect(() => {
    if (!savingSession && session && currentInvoiceId) {
      const invoice = session.invoices.find(
        i => i.invoiceId === currentInvoiceId,
      );
      if (!invoice) return;

      setsavingSession(true);
      const isCompleted = (invoice as InvoiceConsolidate).isCompleted ?? false;
      setCompletingTransaction(isCompleted);
      localSession.saveSession(session, online, isCompleted).then(() => {
        setsavingSession(false);

        if (CompletingTransaction) {
          setCompletingTransaction(false);
        }
      });
    }
  }, [session]);

  return (
    <TransactionsContext.Provider
      value={{
        session,
        setSession: setSession as Dispatch<SetStateAction<Session>>,
        currentInvoiceId,
        setCurrentInvoiceId,
        selectedPayment,
        setSelectedPayment,
        savingSession,
      }}
    >
      {props.children}
    </TransactionsContext.Provider>
  );
}

export const useTransactionsState = (): Context =>
  useContext(TransactionsContext);
