import {Invoice, Session} from '@emporos/api-enterprise';
//import secureLocalStorage from "react-secure-storage";
import {InvoiceConsolidate} from '../api';
import {DIFactory} from '../DIFactory';
import {IInvoiceService} from '../services/IInvoiceService';
import {IInvoiceDatabaseAccess} from './IDatabaseAccess';
import {mapInvoiceConsolidate} from '../utils';
const devLogging = String(process.env.NODE_ENV).trim() === 'development';

export class sessionLocaldb {
  private _key!: string;
  private _token!: string | undefined;
  private _invoiceDb!: IInvoiceDatabaseAccess;
  private _sessionId!: string;
  private _invoiceService!: IInvoiceService;
  private _currentInvoiceId!: string;

  constructor(
    key: string,
    token: string | undefined,
    sessionId: string,
    currentInvoiceId: string,
  ) {
    this._key = key;
    this._token = token;
    this._sessionId = sessionId;
    this._currentInvoiceId = currentInvoiceId;
    this._invoiceDb = DIFactory.getInvoiceDatabaseAccess(this._sessionId);

    this._invoiceService = DIFactory.getInvoiceService(
      this._sessionId,
      this._token?.toString() || '',
    );
  }

  async saveSession(
    value: Session | null,
    isOnline: boolean,
    CompleteTransaction?: boolean,
  ): Promise<void> {
    console.log(
      '*************************************************************************************************************************',
    );
    console.log(
      '*************************************************************************************************************************',
    );
    console.log(
      '*********************************************SAVING SESSION**************************************************************',
    );
    console.log(
      '*************************************************************************************************************************',
    );

    try {
      if (value === null) {
        if (devLogging) {
          window.localStorage.removeItem(this._key);
        }
      } else {
        const valueToStore = value instanceof Function ? value(value) : value;

        devLogging
          ? console.log(
              '%cLocal Storage Set!',
              'background-color:green; color: white',
              valueToStore.invoices,
            )
          : null;
        devLogging
          ? console.log(
              '%cLocal Storage Current Invoice Context:',
              'background-color:green; color: white',
              this._currentInvoiceId,
            )
          : null;

        if (this._currentInvoiceId == '0' || this._currentInvoiceId == '') {
          devLogging
            ? console.log(
                '%cSession loaded from cloud.',
                'background-color:green; color: white',
                valueToStore.invoices,
              )
            : null;
          //New invoice has been added or  new session loaded from cloud.
          for (let i = 0; i < valueToStore.invoices.length; i++) {
            const existingObjectDb = await this._invoiceDb.get(
              valueToStore.invoices[i].invoiceId,
            );
            console.log(
              'existingObjectDb',
              existingObjectDb.serverTransactionID,
            );
            if (existingObjectDb.serverTransactionID > 0) {
            } else {
              valueToStore.invoices[i].isSynced = isOnline;
              await this._invoiceDb.add(valueToStore.invoices[i]);
            }
          }
        }

        if (this._currentInvoiceId !== '0') {
          const existingObjectDb = await this._invoiceDb.get(
            this._currentInvoiceId,
          );
          let sessionObject = valueToStore.invoices.find(
            (n: {invoiceId: string}) => n.invoiceId == this._currentInvoiceId,
          ) as InvoiceConsolidate;

          devLogging
            ? console.log(
                '%cDB Transaction Exists?: ' + this._currentInvoiceId,
                'background-color:green; color: white',
                existingObjectDb,
              )
            : null;

          if (existingObjectDb.serverTransactionID > 0) {
            existingObjectDb.isDeleted = sessionObject.isDeleted;
            //maps items
            existingObjectDb.items = sessionObject.items;
            //maps payments
            existingObjectDb.payments = sessionObject.payments;
            //maps taxes
            existingObjectDb.taxes = sessionObject.taxes;
            //maps signature
            existingObjectDb.signatures = sessionObject.signatures;

            //preserve data version before map
            if (sessionObject.signatureImage) {
              const signatureImageDataVersion =
                existingObjectDb.signatureImage?.dataVersion;
              existingObjectDb.signatureImage = sessionObject.signatureImage;
              // eslint-disable-next-line
              sessionObject!.signatureImage!.dataVersion = signatureImageDataVersion;
            }
            //maps notes
            existingObjectDb.notes = sessionObject.notes;
            //maps customer
            if (sessionObject.customer?.id) {
              sessionObject.customerId = sessionObject.customer.id;
              existingObjectDb.customerId = sessionObject.customer.id;
            }
            existingObjectDb.customer = sessionObject.customer;

            const updatedDbObject = mapInvoiceConsolidate(
              existingObjectDb,
              sessionObject,
            );

            updatedDbObject.isCompleted = CompleteTransaction;
            updatedDbObject.isSynced = isOnline;

            console.log('IS ONLINE', isOnline);
            await this._invoiceDb.update(updatedDbObject);

            try {
              console.log('Invoice DB ID:', updatedDbObject.invoiceId);

              if (isOnline) {
                await this._invoiceService.syncInvoices(
                  updatedDbObject.invoiceId,
                );
              }
              if (updatedDbObject.serverTransactionID > 0) {
                sessionObject = (updatedDbObject as unknown) as Invoice;

                if (updatedDbObject.isDeleted) {
                  console.log('Delete from local DB', updatedDbObject);
                  await this._invoiceDb.delete(updatedDbObject.invoiceId);
                }
                for (let i = 0; i < valueToStore.invoices.length; i++) {
                  if (
                    valueToStore.invoices[i].invoiceId ==
                    updatedDbObject.invoiceId
                  ) {
                    valueToStore.invoices[i].serverTransactionID =
                      updatedDbObject.serverTransactionID;
                    valueToStore.invoices[i].status = updatedDbObject.status;
                    valueToStore.invoices[i].isSynced = isOnline;
                  }
                }
              }
            } catch (error) {
              console.log('Error', error);
            }
          } else {
            sessionObject.isSynced = isOnline;
            await this._invoiceDb.add(sessionObject);
            console.log('Invoice Session ID:', sessionObject.invoiceId);
            if (isOnline) {
              await this._invoiceService.syncInvoices(sessionObject.invoiceId);
            }
          }
        }

        if (devLogging) {
          window.localStorage.setItem(this._key, JSON.stringify(valueToStore));
        }
      }
    } catch (err) {
      console.error(err);
    }
  }
}
