import React, {
  ChangeEvent,
  forwardRef,
  memo,
  MutableRefObject,
  PropsWithoutRef,
  useCallback,
} from 'react';
import styled from 'styled-components';
import {ColorType, Icon, Icons} from './';
import {IndicatorCircle} from './IndicatorCircle';

type Ref =
  | ((instance: HTMLSelectElement | null) => void)
  | MutableRefObject<HTMLSelectElement | null>
  | null;

// extends React.PropsWithoutRef<JSX.IntrinsicElements['select']>
export interface SelectProps<T> {
  color?: ColorType;
  disabled?: boolean;
  icon?: keyof typeof Icons;
  isError?: boolean;
  label?: string;
  loading?: boolean;
  name?: string;
  options: Array<T>;
  optionsText?: Array<string>;
  ref?: Ref;
  selectSize?: SelectSize; // there was a conflict with a built in size property
  style?: {[key: string]: any};
  value: T | null;
  getTextFromValue?(
    value: T,
    optionsText?: Array<string>,
    index?: number,
  ): string;
  onChangeValue(value: T): void;
}
export enum SelectSize {
  Standard = 'Standard',
  Small = 'Small',
}

const Container = styled.label<
  PropsWithoutRef<JSX.IntrinsicElements['select']> & {
    selectSize?: SelectSize;
    isError?: boolean;
    disabled?: boolean;
  }
>`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  flex: 1;
  font-family: ${({theme}) => theme.typography.Main.fontFamily};
  position: relative;
  line-height: ${({theme}) => theme.typography.Main.lineHeight};
  color: ${({theme}) => theme.colors.black};
  border: 1px solid
    ${({theme, isError}) =>
      isError ? theme.colors.error : theme.colors.gray_200};
  background: ${({theme, disabled}) =>
    disabled ? theme.colors.gray_100 : theme.colors.white};
  box-sizing: border-box;
  border-radius: 8px;
  height: ${({selectSize}) =>
    selectSize === SelectSize.Small ? '48px' : '80px'};

  .select-label {
    position: absolute;
    font-size: ${({selectSize}) =>
      selectSize === SelectSize.Small ? '0.666em' : '15px'};
    font-weight: ${({theme}) => theme.typography.Main.fontWeight};
    top: ${({selectSize}) =>
      selectSize === SelectSize.Small ? '-1.25em' : '-14px'};
    left: 16px;
    margin-left: -6px;
    padding: 2px 6px;
    background-color: ${({theme}) => theme.colors.white};
    color: ${({theme, isError}) =>
      isError ? theme.colors.error : theme.colors.blue};
  }

  select {
    appearance: none;
    outline: none;
    box-sizing: border-box;
    border: 0;
    color: ${({theme, isError}) => (isError ? theme.colors.error : 'inherit')};
    font-family: ${({theme}) => theme.typography.Main.fontFamily};
    font-weight: ${({theme}) => theme.typography.Main.fontWeight};
    font-size: ${({theme}) => theme.typography.Main.fontSize};
    padding-left: 16px;
    height: inherit;
    width: 100%;
    cursor: ${({disabled}) => (disabled ? 'not-allowed' : 'pointer')};
    background: transparent
      url("data:image/svg+xml;utf8,<svg width='16' height='16' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'><path d='M11.0673 14.1785L0.38635 3.49748C-0.128783 2.98234 -0.128783 2.14718 0.38635 1.6321L1.63211 0.386338C2.14637 -0.127917 2.97983 -0.128906 3.49529 0.384139L12 8.84903L20.5047 0.384139C21.0202 -0.128906 21.8536 -0.127917 22.3679 0.386338L23.6136 1.6321C24.1288 2.14723 24.1288 2.9824 23.6136 3.49748L12.9327 14.1785C12.4176 14.6936 11.5824 14.6936 11.0673 14.1785Z' fill='black' /></svg>")
      no-repeat;
    background-position: ${({selectSize}) =>
      selectSize === SelectSize.Small
        ? 'right 16px top 21px'
        : 'right 16px top 34px'};
  }

  .select-icon {
    display: flex;
    padding-left: 16px;
  }
`;

const hasOwn = Object.prototype.hasOwnProperty;
function defaultGetTextFromValue<T>(
  v: T,
  optionsText?: Array<string>,
  index?: number,
) {
  if (optionsText && typeof index === 'number') {
    return optionsText[index];
  }
  if (hasOwn.call(v, 'text') && hasOwn.call(v, 'value')) {
    // eslint-disable-next-line
    // @ts-ignore this is the safest duck punch I know how to make
    return v.text;
  } else {
    // coerce to string
    return '' + v;
  }
}

function SelectRef<T>(
  {
    color = 'primary',
    disabled,
    icon,
    isError,
    label,
    loading,
    options,
    optionsText,
    selectSize,
    style,
    value,
    onChangeValue,
    getTextFromValue = defaultGetTextFromValue,
    ...props
  }: SelectProps<T>,
  ref?: Ref,
) {
  const onChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>): void => {
      const v = event.currentTarget.value;
      const val = options.find(
        (o, i) => getTextFromValue(o, optionsText, i) === v,
      );
      onChangeValue(val as T);
    },
    // eslint thinks T is a JS value :unamused:
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChangeValue, options, optionsText, getTextFromValue],
  );
  const selectedIndex = options.findIndex(v => v === value) || 0;

  return (
    <Container
      style={style}
      selectSize={selectSize}
      isError={isError}
      disabled={disabled || loading}
    >
      {label && <span className="select-label">{label}</span>}
      {icon && !loading && (
        <div className="select-icon">
          <Icon icon={icon} variant={isError ? 'error' : color} />
        </div>
      )}
      {loading && (
        <div className="select-icon">
          <IndicatorCircle icon="Spinner" loading />
        </div>
      )}
      <select
        {...props}
        value={
          value === null
            ? ''
            : getTextFromValue(value, optionsText, selectedIndex)
        }
        onChange={onChange}
        ref={ref ? ref : null}
        disabled={disabled || loading}
      >
        {value === null ? (
          <option disabled value="">
            Select
          </option>
        ) : null}
        {options.map((o, i) => {
          const t = getTextFromValue(o, optionsText, i);
          return (
            <option value={t} key={t + i}>
              {t}
            </option>
          );
        })}
      </select>
    </Container>
  );
}

export const Select = memo(forwardRef(SelectRef)) as typeof SelectRef;
