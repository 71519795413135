import {Invoice, InvoiceStatusEnum} from '@emporos/api-enterprise';
import {orderBy} from 'lodash';
import {
  InvoiceConsolidate,
  OfflineInvoice,
  OfflineSynced,
  SessionUpdates,
  useTransactionsState,
} from '../';

import {filterDeletedInvoiceChildren} from './';

export const useSession = (): {
  siteId: number;
  pendingInvoices: Array<Invoice>;
  completedInvoices: Array<InvoiceConsolidate>;
  updateSession: (updates: SessionUpdates, currentInvoiceId?: string) => void;
  canClose: boolean;
  canCreateInvoice: boolean;
} => {
  const {session, setSession} = useTransactionsState();

  const invoices = session.invoices.filter(
    invoice => !(invoice as OfflineSynced).isDeleted,
  );
  const siteId = session.siteId;

  const pendingInvoices = orderBy(
    invoices
      .filter(
        invoice =>
          !(invoice as OfflineInvoice).isCompleted &&
          invoice.status !== InvoiceStatusEnum.Complete &&
          // Filter out deleted status' temporarily for partial nplex implementation - Product Team
          invoice.status !== InvoiceStatusEnum.Deleted &&
          invoice.status !== InvoiceStatusEnum.Accepted &&
          invoice.status !== InvoiceStatusEnum.Error,
      )
      .map(filterDeletedInvoiceChildren),
    ['saleDate'],
    ['desc'],
  );
  const completedInvoices = orderBy(
    invoices
      .filter(
        invoice =>
          (invoice as OfflineInvoice).isCompleted ||
          invoice.status === InvoiceStatusEnum.Complete ||
          invoice.status === InvoiceStatusEnum.Error ||
          invoice.status === InvoiceStatusEnum.Accepted,
      )
      .map(filterDeletedInvoiceChildren),
    ['saleDate'],
    ['desc'],
  );
  const canClose = !invoices.some(
    invoice =>
      invoice.status !== InvoiceStatusEnum.Complete &&
      invoice.status !== InvoiceStatusEnum.Deleted &&
      invoice.status !== InvoiceStatusEnum.Accepted &&
      invoice.status !== InvoiceStatusEnum.Error,
  );
  const canCreateInvoice = !pendingInvoices.some(
    invoice => !invoice.customer && !invoice.items.length,
  );

  const updateSession = async (updates: SessionUpdates) => {
    if (typeof updates === 'function') {
      setSession(prevSession => ({
        ...prevSession,
        ...updates(session),
      }));
    } else {
      setSession(prevSession => ({
        ...prevSession,
        triggerSync: true,
        ...updates,
      }));
    }
  };

  return {
    siteId,
    pendingInvoices,
    completedInvoices,
    updateSession,
    canClose,
    canCreateInvoice,
  };
};
