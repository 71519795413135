import {Redirect, Router} from '@reach/router';
import {
  AcknowledgementsIntegration as Acknowledgements,
  AcknowledgementsInfoIntegration as AcknowledgementsInfo,
  AddCustomer,
  CompleteTransactionIntegration as CompleteTransaction,
  CompletedTransactionIntegration as CompletedTransaction,
  ComplianceIntegration as Compliance,
  CustomerInfo,
  CustomerInfoWillCall,
  CustomerPaymentPage as CustomerPayment,
  Home as Sales,
  HostedPaymentIntegration as HostedPayment,
  IdCheckIntegration as IdCheck,
  OtcSearchIntegration as OtcSearch,
  PaymentInfoPage as PaymentInfo,
  ReceiptsIntegration as Receipts,
  SettingsDeveloper,
  SettingsHome,
  SettingsSupport,
  TransactionIntegration as Transaction,
  Unknown as NotFound,
} from '../';
import {MainLayout} from './';

export function Routes(): JSX.Element {
  return (
    <Router>
      <MainLayout path="sales">
        <NotFound default />
        <Sales path="/" />

        <Transaction path="transactions">
          <CustomerInfoWillCall path="customer">
            <AddCustomer path="add-customer" />
          </CustomerInfoWillCall>
          <OtcSearch path="otc-search">
            <AddCustomer path="add-customer" />
          </OtcSearch>

          <IdCheck path="payments/id-check">
            <CustomerInfoWillCall path="customer-info" canAddCustomer={false} />
          </IdCheck>
          <Compliance path="payments/compliance">
            <CustomerInfoWillCall path="customer-info" canAddCustomer={false} />
          </Compliance>
          <CustomerPayment path="payments/customer-payment">
            <CustomerInfoWillCall path="customer-info" canAddCustomer={false} />
            <AddCustomer path="add-customer" addToAr />
            <PaymentInfo path="payment-info" canVoid={true} />
            <HostedPayment path="hosted-payment" />
          </CustomerPayment>
          <Acknowledgements path="payments/acknowledgements">
            <CustomerInfoWillCall path="customer-info" canAddCustomer={false} />
            <PaymentInfo path="payment-info" canVoid={false} />
            <AcknowledgementsInfo path="info" />
          </Acknowledgements>
          <Receipts path="payments/receipts">
            <CustomerInfoWillCall path="customer-info" canAddCustomer={false} />
            <PaymentInfo path="payment-info" canVoid={false} />
          </Receipts>
          <CompleteTransaction path="payments/complete">
            <CustomerInfoWillCall path="customer-info" canAddCustomer={false} />
            <PaymentInfo path="payment-info" canVoid={false} />
          </CompleteTransaction>
        </Transaction>

        <CompletedTransaction path="completed">
          <CustomerInfo path="customer-info" viewOnly />
        </CompletedTransaction>

        <SettingsHome path="settings" />
        <SettingsSupport path="settings/support" />
        <SettingsDeveloper path="settings/developer" />
      </MainLayout>

      <Redirect from="*" to="/sales" noThrow />
    </Router>
  );
}
