import {
  Customer as InvoiceCustomer,
  CustomerAccount,
  CustomerAccount as InvoiceCustomerAccount,
  CustomerResponseApiResponse,
  EligibilitySearchMatch,
  EligibilitySearchMatchListApiResponse,
  PaymentType,
} from '@emporos/api-enterprise';
import {
  Button,
  Gutter,
  Page,
  Row,
  ScrollContainer,
  SelectCustom,
  Stack,
  Text,
  TextVariant as TV,
  Variant as BV,
} from '@emporos/components';
import moment from 'moment';
import {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import styled from 'styled-components';
import {
  formatCustomerName,
  getPaymentTypeId,
  mapInvoiceCustomer,
  OfflineInvoice,
  pds,
} from '../../../../../';
import {BillBoard, EmployeeSearch, PaymentRef as Handle} from './';

interface Props {
  isOnline: boolean;
  amount: number;
  setAmount: (v: number) => void;
  totalDue: number;
  paymentTenders: PaymentType[];
  onChangeActivePayment(account: CustomerAccount): void;
  onSearchEligibility: (
    query: string,
  ) => Promise<EligibilitySearchMatchListApiResponse>;
  onGetEmployee: (
    customer: EligibilitySearchMatch,
  ) => Promise<CustomerResponseApiResponse>;
  commit: (changes?: Partial<OfflineInvoice> | undefined) => void;
}

const CustomerRow = styled(Row)`
  display: flex;
  border: 1px solid ${({theme}) => theme.colors.gray_200};
  height: 80px;
  border-radius: 8px;
  padding: 16px;
`;

function isAcceptablePayment(
  account: CustomerAccount,
  amount: number,
  totalDue: number,
) {
  return account.creditLimit - account.balance >= amount && amount <= totalDue;
}

export const PD = forwardRef<Handle, Props>(function PDPayment(
  {
    isOnline,
    amount,
    setAmount,
    totalDue,
    paymentTenders,
    onChangeActivePayment,
    onSearchEligibility,
    onGetEmployee,
    commit,
  },
  ref,
): JSX.Element {
  const [searchPanelOpen, setSearchPanelOpen] = useState(false);
  const [customer, setCustomer] = useState<InvoiceCustomer | null>(null);
  const [
    customerAccount,
    setCustomerAccount,
  ] = useState<InvoiceCustomerAccount | null>(null);

  useImperativeHandle(ref, () => ({
    onConfirmPayment() {
      if (!customerAccount) {
        return Promise.reject(
          new TypeError(
            'Attempted to pay with PD without having a PD account selected',
          ),
        );
      } else if (!isAcceptablePayment(customerAccount, amount, totalDue)) {
        return Promise.reject(
          new TypeError('Selected account has insufficient funds.'),
        );
      }
      return Promise.resolve({
        paymentId: customerAccount.accountNumber,
        amountApproved: amount,
        paymentTypeId: getPaymentTypeId('PD', paymentTenders),
      });
    },
    isAcceptablePayment() {
      return customerAccount
        ? isAcceptablePayment(customerAccount, amount, totalDue)
        : false;
    },
  }));

  /**
   * `onChangeActivePayment` is a prop from the CustomerPayment manager
   * component to listen to the lifecycle of this component and call
   * `ref.isAcceptablePayment()` to determine if the submit button is enabled or
   * not. This is an awkward React pattern, but enables us to delegate the
   * implementation of payment-specific logic within each payment type.
   */
  useEffect(() => {
    if (customerAccount) {
      onChangeActivePayment(customerAccount);
    }
  }, [customerAccount]);

  return (
    <ScrollContainer>
      <Stack gutter={Gutter.L} style={{flex: 1}}>
        <BillBoard value={amount} onChange={setAmount} totalDue={totalDue} />
        <CustomerRow justify="space-between" align="center">
          {customer ? (
            <>
              <Stack gutter={Gutter.XXS}>
                <Text variant={TV.Main}>{formatCustomerName(customer)}</Text>
                <Text variant={TV.SubMainLight}>
                  DOB: {moment.utc(customer.dob).format('MM/DD/YYYY')}
                </Text>
              </Stack>
              <Button
                variant={BV.Link}
                onClick={() => setSearchPanelOpen(true)}
                style={{padding: 0}}
              >
                Change
              </Button>
            </>
          ) : (
            <>
              <Stack gutter={Gutter.XXS}>
                <Text variant={TV.Main}>Employee</Text>
              </Stack>
              <Button
                variant={BV.Link}
                onClick={() => setSearchPanelOpen(true)}
                style={{padding: 0}}
              >
                Search
              </Button>
            </>
          )}
        </CustomerRow>
        <Row>
          <SelectCustom<InvoiceCustomerAccount>
            data-testid="select-accounts"
            label="PD Accounts"
            value={customerAccount}
            options={customer?.accounts ? pds(customer) : []}
            getOptionValue={({accountId = ''}) => accountId.toString()}
            onChange={selection => {
              setCustomerAccount(selection);
            }}
            renderOption={({accountNumber, balance = 0, creditLimit = 0}) => (
              <Stack gutter={Gutter.XXS} style={{flex: 1}}>
                <Text variant={TV.Main}>
                  {`Available: $${(creditLimit - balance).toFixed(2)}`}
                </Text>
                <Text variant={TV.SubMainLight}>
                  {`Limit: $${creditLimit.toFixed(
                    2,
                  )} • Account: ${accountNumber}`}
                </Text>
              </Stack>
            )}
            placeholder={customer ? undefined : 'No Account'}
            disabled={!Boolean(customer)}
          />
        </Row>
      </Stack>

      <Page level={3} open={searchPanelOpen} style={{left: 36, zIndex: 1}}>
        <EmployeeSearch
          label="Employee Number or Badge ID"
          online={isOnline}
          onAddCustomer={eligibility =>
            onGetEmployee(eligibility).then(response => {
              if (!response.data) {
                return;
              }
              const mappedCustomer = mapInvoiceCustomer(response.data);
              setCustomer(mappedCustomer);
              commit({customer: mappedCustomer});
              setCustomerAccount(null);
              setSearchPanelOpen(false);
            })
          }
          onCancel={() => setSearchPanelOpen(false)}
          onSearch={onSearchEligibility}
        />
      </Page>
    </ScrollContainer>
  );
});
