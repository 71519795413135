import {
  Button,
  FooterGroup,
  Gutter,
  Header,
  Modal,
  ScrollContainer,
  Stack,
  Variant as BV,
} from '@emporos/components';
import {RouteComponentProps} from '@reach/router';
import assert from 'assert';
import {isEqual} from 'lodash';
import {memo, useCallback, useEffect, useState} from 'react';
import {InvoiceConsolidate, useTransactionsConfig} from '../../../';
import {CreateSession, SessionConfig} from '../../';
import {InvoiceStatusEnum} from '@emporos/api-enterprise';

function SettingsComponent(_: RouteComponentProps): JSX.Element {
  const {
    closeSession,
    closeSessionLoading,
    session,
    updatePaymentDeviceAddress,
    updatePaymentDeviceAddressLoading,
  } = useTransactionsConfig();

  const [config, setConfig] = useState<SessionConfig>({
    site: null,
    station: null,
    paymentDeviceAddress: null,
    paymentDevicePort: null,
    till: null,
    tillStartingAmount: null,
  });
  const [originalConfig, setOriginalConfig] = useState<SessionConfig>();
  const [enableConfirm, setEnableConfirm] = useState(false);
  const [openConfirmCloseModal, setOpenConfirmCloseModal] = useState(false);
  const [openSalesModal, setOpenSalesModal] = useState(false);

  assert(
    session !== null,
    'Internal Error: tried to render Settings without a session',
  );

  const {
    site,
    startingCashBalance,
    station,
    till,
    paymentDeviceAddress,
    paymentDevicePort,
  } = session;

  useEffect(() => {
    const _config = {
      ...config,
      site,
      station,
      till,
      paymentDeviceAddress: paymentDeviceAddress || '',
      paymentDevicePort: paymentDevicePort || '',
      tillStartingAmount: startingCashBalance || 0,
    };
    setConfig(_config);
    setOriginalConfig(_config);
  }, [session]);

  useEffect(() => {
    setEnableConfirm(!isEqual(originalConfig, config));
  }, [config]);

  const onCloseSession = () => {
    console.log(
      session.invoices.filter(
        invoice =>
          invoice.status === InvoiceStatusEnum.Active &&
          !(invoice as InvoiceConsolidate).isDeleted &&
          !(invoice as InvoiceConsolidate).isCompleted,
      ),
    );
    if (
      session.invoices.filter(
        invoice =>
          invoice.status === InvoiceStatusEnum.Active &&
          !(invoice as InvoiceConsolidate).isDeleted &&
          !(invoice as InvoiceConsolidate).isCompleted,
      ).length > 0
    ) {
      setOpenSalesModal(true);
    } else {
      setOpenConfirmCloseModal(true);
    }
  };

  const onConfirm = useCallback(async () => {
    const {paymentDeviceAddress} = config;
    await updatePaymentDeviceAddress(session, paymentDeviceAddress as string);
  }, [config, session]);

  return (
    <>
      <Stack gutter={Gutter.None} style={{height: '100%'}}>
        <Header title="My Session" />
        <ScrollContainer style={{paddingBottom: 16}}>
          <CreateSession
            configSession
            loading={closeSessionLoading}
            sites={[site]}
            stations={[station]}
            config={config}
            onChange={setConfig}
            tills={[till]}
          />
        </ScrollContainer>
        <FooterGroup>
          <Button
            type="button"
            variant={BV.Danger}
            flex
            onClick={onCloseSession}
            loading={closeSessionLoading}
          >
            Close Session
          </Button>
          <Button
            flex
            disabled={!enableConfirm}
            loading={updatePaymentDeviceAddressLoading}
            onClick={onConfirm}
          >
            Confirm
          </Button>
        </FooterGroup>
      </Stack>
      <Modal
        visible={openConfirmCloseModal}
        data-testid="Modal__ConfirmClose"
        icon="CashRegister"
        color="primary"
        title="Close My Session"
        subtitle="Please confirm you want to close your session. You cannot reopen this session once closed."
        buttonText="Confirm"
        onCancel={() => setOpenConfirmCloseModal(false)}
        onContinue={closeSession}
      />
      <Modal
        visible={openSalesModal}
        data-testid="Modal__OpenSales"
        icon="Warning"
        color="error"
        title="Session Has Open or Unsynced Sales"
        subtitle="Your session still has sales that are open or unsynced. Please complete or resync all transactions first."
        buttonText="Okay"
        onContinue={() => setOpenSalesModal(false)}
      />
    </>
  );
}

export const SettingsHome = memo(SettingsComponent);
