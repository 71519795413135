import {
  EligibilitySearchMatch,
  EligibilitySearchMatchListApiResponse,
} from '@emporos/api-enterprise';
import {ScanResult} from '@emporos/barcodes';
import {
  Button,
  Variant as BV,
  Gutter,
  Header,
  Row,
  RowItem,
  ScrollContainer,
  Stack,
  Text,
  TextInput,
  TextVariant as TV,
  Illustration,
  Intent,
} from '@emporos/components';
import {PageBarcodeScanner} from '@emporos/components-pos';
import {debounce} from 'lodash';
import {ChangeEvent, useCallback, useEffect, useState} from 'react';
import {formatNamePart, useLog, UserLogTypes} from '../../../../../';

export interface EmployeeSearchProps {
  onSearch: (query: string) => Promise<EligibilitySearchMatchListApiResponse>;
  onAddCustomer: (customer: EligibilitySearchMatch) => Promise<void>;
  onCancel: () => void;
  online: boolean;
  label?: string;
}

export function EmployeeSearch({
  onSearch,
  onAddCustomer,
  onCancel,
  online,
  label,
}: EmployeeSearchProps): JSX.Element {
  const {logUserSelection} = useLog();
  const [eligibilities, setEligibilities] = useState<EligibilitySearchMatch[]>(
    [],
  );
  const [selectedEligibility, setSelectedEligibility] = useState<
    EligibilitySearchMatch
  >();
  const [query, setQuery] = useState('');
  const [searchInputLoading, setSearchInputLoading] = useState(false);
  const [addButtonLoading, setAddButtonLoading] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const searchedText = hasSearched
    ? 'No employee found'
    : 'Search for employee above';

  useEffect(() => {
    if (online) {
      return;
    }
    setHasSearched(false);
  }, [online]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setQuery(val);
    onSearchDebounced(val);
  };

  const onScan = (scanResult: ScanResult) => {
    const rawQuery = scanResult.raw;
    logUserSelection(UserLogTypes.ScannedBadge);
    setQuery(rawQuery);
    onSearchDebounced(rawQuery);
  };

  const onSearchDebounced = useCallback(
    debounce(
      async searchQuery => {
        setSearchInputLoading(true);

        if (searchQuery.length < 3) {
          setEligibilities([]);
          setHasSearched(false);
        } else {
          try {
            const response = await onSearch(searchQuery);
            setEligibilities(response.data || []);
          } catch (e) {
            setEligibilities([]);
          }
          setHasSearched(true);
        }

        setSearchInputLoading(false);
      },
      500,
      {leading: true},
    ),
    [setEligibilities],
  );

  return (
    <Stack>
      <Header style={{paddingTop: 10}} title="Select Employee" />
      <TextInput
        loading={searchInputLoading}
        label={label ?? 'Name, Employee Number or Badge ID'}
        icon="IdCard"
        value={query}
        onChange={onChange}
        onClear={() => {
          setQuery('');
          setEligibilities([]);
          setHasSearched(false);
          setSelectedEligibility(undefined);
        }}
        data-testid="search-customer"
        disabled={!online}
      />

      <ScrollContainer>
        {(!online || !eligibilities.length) && (
          <Stack
            align="center"
            justify="center"
            gutter={Gutter.None}
            style={{height: '100%'}}
          >
            {online ? (
              <Illustration illustration="SearchUser" />
            ) : (
              <Illustration illustration="ConnectionLost" />
            )}
            <Text
              variant={TV.Main}
              intent={online && hasSearched && Intent.Alert}
            >
              {!online ? 'Cannot search without a connection' : searchedText}
            </Text>
          </Stack>
        )}
        {online && !!eligibilities.length && (
          <Stack gutter={Gutter.S}>
            {eligibilities.map(eligibility => {
              return (
                <RowItem
                  key={`${eligibility.firstName}${eligibility.employeeNumber}`}
                  title={`${formatNamePart(
                    eligibility.firstName,
                  )} ${formatNamePart(eligibility.lastName)}`}
                  subtitle={`Employee: ${eligibility.employeeNumber} • Badge: ${eligibility.badgeNumber}`}
                  selected={
                    eligibility.employeeNumber ===
                    selectedEligibility?.employeeNumber
                  }
                  onClick={() =>
                    !addButtonLoading && setSelectedEligibility(eligibility)
                  }
                  rightText={eligibility.eligible ? '' : 'Ineligible'}
                  inactive={!eligibility.eligible}
                />
              );
            })}
          </Stack>
        )}
      </ScrollContainer>
      <Row gutter={Gutter.XL}>
        <Button variant={BV.Secondary} onClick={() => onCancel()} flex>
          Cancel
        </Button>
        <Button
          loading={addButtonLoading}
          disabled={!selectedEligibility || !online}
          flex
          data-testid="add-selectedEligibility"
          onClick={async () => {
            if (!selectedEligibility) {
              return;
            }
            setAddButtonLoading(true);
            try {
              await onAddCustomer(selectedEligibility);
            } finally {
              setAddButtonLoading(false);
              setQuery('');
              setEligibilities([]);
              setHasSearched(false);
              setSelectedEligibility(undefined);
            }
          }}
        >
          Add Employee
        </Button>
      </Row>
      <PageBarcodeScanner onScan={onScan} />
    </Stack>
  );
}
