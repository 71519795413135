import React, {PropsWithoutRef, forwardRef, memo, useState} from 'react';
import InputMask from 'react-input-mask';
import styled, {keyframes, css} from 'styled-components';
import {Icon, Icons, IconSize, IndicatorCircle} from './';

export enum TextSize {
  Large = 'Large',
  Standard = 'Standard',
}
export interface TextInputProps {
  label?: string;
  isError?: boolean;
  icon?: keyof typeof Icons;
  disabled?: boolean;
  onClear?: () => void;
  mask?: string;
  // TODO: figure out how to type this + overloads correctly
  // eslint-disable-next-line
  as?: any;
  loading?: boolean;
  inputSize?: TextSize;
}

// Keyframe solution from
// https://css-tricks.com/snippets/css/shake-css-keyframe-animation/
const shake = keyframes`
10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
`;

const animation = () =>
  css`
    animation: ${shake} 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  `;

const Container = styled.div<
  TextInputProps & {
    floating: boolean;
  }
>`
  box-sizing: border-box;
  color: ${({theme}) => theme.colors.black};
  font-family: ${({theme}) => theme.typography.Main.fontFamily};
  font-weight: ${({theme}) => theme.typography.Main.fontWeight};
  font-size: ${({theme}) => theme.typography.Main.fontSize};
  line-height: ${({theme}) => theme.typography.Main.lineHeight};
  position: relative;
  width: 100%;

  &.input-error {
    ${animation};
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }

  .input-text-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${({inputSize}) =>
      inputSize === TextSize.Large ? '80px' : '50px'};
    position: absolute;
    left: 16px;

    .indicator-circle {
      ${({disabled}) => {
        if (disabled) {
          return 'opacity: 0.4;';
        }
      }};
    }
  }

  label {
    position: absolute;
    top: ${({inputSize}) => (inputSize === TextSize.Large ? '25px' : '12px')};
    left: ${({icon}) => (icon ? '52px' : '16px')};
    ${({icon}) => (icon ? 'left: 56px;' : '')}
    margin-left: -6px;
    padding: 2px 6px;
    background-color: transparent;
    transition: all 0.1s ease-in;
    color: ${({theme, isError}) =>
      isError ? theme.colors.error : theme.colors.gray_300};
  }
  ${({floating}) => {
    // if we have the `floating` flag, add this selector to the following block:
    return floating ? `label,` : '';
  }}
  :focus-within label {
    background-color: ${({theme}) => theme.colors.white};
    font-size: ${({inputSize}) =>
      inputSize === TextSize.Large ? '15px' : '0.666em'};
    top: ${({inputSize}) =>
      inputSize === TextSize.Large ? '-14px' : '-1.25em'};
    left: 16px;
    color: ${({theme, isError}) =>
      isError ? theme.colors.error : theme.colors.blue};
  }
  textarea {
    padding-top: 12px;
    resize: vertical;
  }
  textarea,
  input {
    background-color: ${({theme, disabled}) =>
      disabled ? theme.colors.gray_100 : theme.colors.white};
    border: 1px solid
      ${({theme, isError}) =>
        isError ? theme.colors.error : theme.colors.gray_200};
    border-radius: 8px;
    min-height: ${({inputSize}) =>
      inputSize === TextSize.Large ? '80px' : '50px'};
    color: ${({disabled, theme}) =>
      disabled ? theme.colors.steel : 'inherit'};
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    outline: 0;
    padding-left: ${({icon}) => (icon ? '52px' : '16px')};
    height: inherit;
    width: inherit;
    -webkit-appearance: none;
    margin: 0;

    ${({disabled}) =>
      disabled && {
        cursor: 'not-allowed',
      }}

    :focus {
      outline: none;
      border-color: ${({theme, isError}) =>
        isError ? theme.colors.error : theme.colors.blue};
    }
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const ClearButton = styled.span<TextInputProps>`
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 16px;
  height: ${({inputSize}) => (inputSize === TextSize.Large ? '80px' : '50px')};
  display: flex;
  align-items: center;
`;

export const TextInput = memo(
  forwardRef<
    InputMask,
    TextInputProps & PropsWithoutRef<JSX.IntrinsicElements['input']>
  >(
    (
      {
        as = InputMask,
        inputSize = TextSize.Standard,
        icon,
        label,
        isError,
        disabled,
        onClear,
        style,
        mask,
        // maskChar,
        loading,
        ...props
      },
      ref,
    ) => {
      const Input = as;

      // this is a strange usage of useState because we want to ensure that the id
      // is constant through the lifecycle of this component
      const [id] = useState(
        () => props.id || `text-input-${Math.floor(Math.random() * 100000)}`,
      );
      const [indicatorCircleType, setIndicatorCircleType] = useState<
        'gray' | 'primary' | 'error'
      >('gray');
      const hasValue =
        typeof props.value === 'number' ||
        (typeof props.value === 'string' && props.value !== '');

      return (
        <Container
          inputSize={inputSize}
          floating={hasValue}
          disabled={disabled}
          isError={Boolean(isError)}
          icon={icon}
          onFocus={() => setIndicatorCircleType('primary')}
          onBlur={() => setIndicatorCircleType('gray')}
          style={style}
          className={isError ? 'input-error' : ''}
          data-testid={`textInput-${label}`}
        >
          {icon && (
            <div className="input-text-icon">
              <IndicatorCircle
                className="indicator-circle"
                size="small"
                icon={loading ? 'Spinner' : icon}
                loading={loading}
                variant={isError ? 'error' : indicatorCircleType}
              />
            </div>
          )}
          <Input
            ref={ref}
            disabled={disabled}
            type="text"
            mask={mask || ''}
            // maskChar={maskChar}
            {...props}
            id={id}
          />
          <label htmlFor={id}>{label}</label>
          {onClear && hasValue && props.value !== '$0.00' && (
            <ClearButton
              onClick={onClear}
              data-testid="textInput-clear"
              inputSize={inputSize}
            >
              <Icon icon="X" size={IconSize.S} variant="gray" />
            </ClearButton>
          )}
        </Container>
      );
    },
  ),
);
TextInput.displayName = 'TextInput';
