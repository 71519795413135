import {
  CashDrawer,
  Customer,
  InvoiceStatusEnum,
  SignatureImage,
  Site,
  Station,
} from '@emporos/api-enterprise';
import {navigate} from '@reach/router';
import assert from 'assert';
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  AuthClaim,
  InvoiceConsolidate,
  OfflineInvoice,
  OfflineSynced,
  Session,
  useAlertState,
  useAuthentication,
} from '../';
import {useApi} from './ApiProvider';
import {deleteDatabase} from '../localDb/dbcontext';

const offline = !navigator.onLine;

export type TransactionsConfigContextValue = {
  session: Session | null;
  sites: Site[];
  setSession: Dispatch<SetStateAction<Session | null>>;
  loading: boolean;
  ready: boolean;
  createSessionLoading: boolean;
  createSession(
    site: Site,
    station: Station,
    till: CashDrawer,
    tillStartingAmount: number,
    paymentDeviceAddress?: string,
    paymentDevicePort?: string,
  ): Promise<Session | null>;
  closeSessionLoading: boolean;
  closeSession(): void;
  loadUserSession(): void;
  hardLoadingSession: boolean;
  sessionClosed: boolean;
  setSessionClosed: Dispatch<SetStateAction<boolean>>;
  updatePaymentDeviceAddress(
    session: Session,
    paymentDeviceAddress?: string,
  ): Promise<Session | null>;
  updatePaymentDeviceAddressLoading: boolean;
};
type Props = PropsWithChildren<unknown>;

const noop = () => undefined;

export const transactionsConfigContext = createContext<
  TransactionsConfigContextValue
>({
  session: null,
  sites: [],
  setSession: x => x,
  loading: true,
  ready: false,
  createSessionLoading: false,
  createSession: () => Promise.resolve(null),
  closeSessionLoading: false,
  closeSession: noop,
  loadUserSession: noop,
  hardLoadingSession: false,
  sessionClosed: false,
  setSessionClosed: x => x,
  updatePaymentDeviceAddress: () => Promise.resolve(null),
  updatePaymentDeviceAddressLoading: false,
});

export function TransactionsConfigProvider(props: Props): JSX.Element {
  const api = useApi();
  const {user} = useAuthentication();
  const {notification} = useAlertState();

  const [session, setSession] = useState<Session | null>(null);

  const [sessionClosed, setSessionClosed] = useState<boolean>(false);
  const [sites, setSites] = useState<Site[]>([]);
  const [hardLoadingSession, setHardLoadingSession] = useState(false);
  const [loadingSession, setLoadingSession] = useState(true);

  const {run: getSites} = api.GetSites([{}]);
  const {run: getMySession} = api.GetMySession();
  const {run: getCustomer} = api.GetCustomer();
  const {run: closeSession, loading: closeSessionLoading} = api.CloseSession();
  const {
    run: createSession,
    loading: createSessionLoading,
  } = api.CreateSession();

  const {
    run: updatePaymentDeviceAddress,
    loading: updatePaymentDeviceAddressLoading,
  } = api.UpdatePaymentDeviceAddress();

  const _updatePaymentDeviceAddress = useCallback(
    async (session: Session, paymentDeviceAddress?: string) => {
      try {
        // transmit the new payment device address to the API
        const remote = await updatePaymentDeviceAddress({
          sessionId: session.sessionId,
          paymentDeviceAddress,
        });

        /**
         * copy the session and replace the payment device address
         * that came back from the API.
         */
        const newSession = {
          ...session,
          paymentDeviceAddress: remote.paymentDeviceAddress,
        };

        // update the session state
        setSession(newSession);

        // notify the user that the operation was successful
        notification({
          type: 'success',
          icon: 'Checkmark',
          title: 'Payment Device Address Updated',
          description:
            'We successfully updated the payment device address for your session.',
        });

        return newSession;
      } catch (error) {
        notification({
          type: 'error',
          icon: 'X',
          title: 'Update Payment Device Address Failed',
          description:
            "We couldn't update the payment device address for your session.",
        });
        return null;
      }
    },
    [],
  );

  const _createSession = useCallback(
    async (
      site: Site,
      station: Station,
      till: CashDrawer,
      tillStartAmount: number,
      paymentDeviceAddress?: string,
      paymentDevicePort?: string,
    ) => {
      let remote: Session;
      try {
        // Create a new session.
        remote = await createSession({
          siteId: site.siteId,
          stationId: station.stationId,
          tillId: till.cashDrawerId,
          startingCashBalance: tillStartAmount,
          paymentDeviceAddress: paymentDeviceAddress,
          paymentDevicePort: paymentDevicePort,
        });
      } catch (error) {
        notification({
          type: 'error',
          icon: 'X',
          title: 'Open Session Failed',
          description: "We couldn't create a session with your selections.",
        });
        return null;
      }

      setSession(remote);

      return remote;
    },
    [],
  );

  const _closeSession = useCallback(async () => {
    assert(
      session !== null,
      'Internal Error: called closeSession() with no active session.',
    );

    if (
      session.invoices
        .filter(
          invoice =>
            !(invoice as OfflineSynced).isDeleted &&
            invoice.status !== InvoiceStatusEnum.Deleted &&
            invoice.status !== InvoiceStatusEnum.Error &&
            invoice.status !== InvoiceStatusEnum.Accepted &&
            !(invoice as InvoiceConsolidate).isCompleted,
        )
        .some(invoice => invoice.status !== InvoiceStatusEnum.Complete)
    ) {
      return;
    }

    try {
      await closeSession();
      deleteDatabase(user?.profile[AuthClaim.UserId]);
      setSessionClosed(true);
      setSession(null);

      return navigate('/sales');
    } catch (error) {
      notification({
        type: 'error',
        icon: 'X',
        title: 'Close Session Failed',
        description:
          "We couldn't close your session. Please check your internet connection and try reloading the app.",
      });
    }
  }, [session]);

  const loadUserSession = async (): Promise<Session | null> => {
    if (offline) {
      if (session !== null) {
        return session;
      }
    }

    return getMySession({})
      .then(async sessions => {
        const next = sessions[0];
        if (next && 'sessionId' in next) {
          next.invoices.forEach(invoice => {
            if (invoice.signatures[0]?.signatureImage) {
              (invoice as OfflineInvoice).signatureImage = {
                ...invoice.signatures[0].signatureImage,
                isSynced: true,
              } as SignatureImage;
            }
          });
          await Promise.all(
            next.invoices.map(async invoice => {
              const {customerId} = invoice;
              if (!customerId) {
                return Promise.resolve();
              }
              const {data, error} = await getCustomer({customerId});
              if (error) {
                return Promise.reject(error);
              }
              invoice.customer = data as Customer;
            }),
          );
          setSession(next);
        } else {
          // Clear local session if the corresponding server session is closed.
          setSession(null);
        }
        return next || null;
      })
      .catch(error => {
        // Clear local session if the corresponding server session is closed.
        setSession(null);

        return error;
      });
  };
  const loadSites = async () => {
    const {data} = await getSites({});
    if (data) {
      setSites(data);
    }
  };
  const _loadUserSession = useCallback(() => {
    setHardLoadingSession(true);
    loadUserSession().finally(() => {
      setHardLoadingSession(false);
    });
  }, [loadUserSession]);

  const initialize = async () => {
    if (!loadingSession) {
      setLoadingSession(true);
    }
    // Adding a try catch here because loadUserSession was silently failing
    try {
      await Promise.all([loadUserSession()]);

      if (!offline) {
        await Promise.all([loadSites()]);
      }
    } catch (err) {
      notification({
        type: 'error',
        icon: 'X',
        title: 'Session Failed to Load',
        description:
          'Please check your internet connection and reload the app.',
      });
    }
  };

  const value: TransactionsConfigContextValue = useMemo(
    () => ({
      session,
      sites,
      setSession,
      loading: loadingSession,
      ready: !loadingSession,
      createSession: _createSession,
      createSessionLoading,
      closeSession: _closeSession,
      closeSessionLoading,
      sessionClosed,
      setSessionClosed,
      loadUserSession: _loadUserSession,
      hardLoadingSession,
      updatePaymentDeviceAddress: _updatePaymentDeviceAddress,
      updatePaymentDeviceAddressLoading,
    }),
    [
      session,
      sites,
      setSession,
      loadingSession,
      createSessionLoading,
      _createSession,
      closeSessionLoading,
      _closeSession,
      hardLoadingSession,
      sessionClosed,
      setSessionClosed,
      _updatePaymentDeviceAddress,
      updatePaymentDeviceAddressLoading,
    ],
  );

  useEffect(() => {
    if (user) {
      initialize().then(() => {
        setLoadingSession(false);
      });
    }
  }, [user]);

  return (
    <transactionsConfigContext.Provider value={value}>
      {props.children}
    </transactionsConfigContext.Provider>
  );
}

export function useTransactionsConfig(): TransactionsConfigContextValue {
  return useContext(transactionsConfigContext);
}
