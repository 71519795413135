import Dexie, {Table} from 'dexie';
import {InvoiceConsolidate, InvoicePaymentConsolidate} from '../api';

//import {clearAllTables, applyEncryptionMiddleware} from 'dexie-encrypted';

//let _hiloDb: HiloDb;

export class HiloDb extends Dexie {
  public InvoiceLocaldb!: Table<InvoiceConsolidate>;
  public InvoicePaymetLocaldb!: Table<InvoicePaymentConsolidate>;

  constructor(sessionId: string) {
    super('HiloDb');
    /* eslint-disable-next-line @typescript-eslint/no-this-alias */
    //_hiloDb = this;
    const view = new Uint8Array(32);

    view[0] = sessionId.toString().charCodeAt(0);

    //const key = view;

    // applyEncryptionMiddleware(
    //   _hiloDb,
    //   key,
    //   {
    //     InvoiceLocaldb: 'NON_INDEXED_FIELDS',
    //   },
    //   clearAllTables,
    // );

    this.version(1).stores({
      InvoiceLocaldb:
        'invoiceId, sessionId, recordStatus, status, serverTransactionID, isSynced, isDeleted',
      InvoicePaymetLocaldb:
        'invoicePaymentId,invoiceId,invoicePaymentStatus,recordStatus',
    });
  }
}

export const deleteDatabase = async (sessionId: string): Promise<void> => {
  const db = new HiloDb(sessionId);
  await db.delete();
};
