import {createContext, useContext} from 'react';

export declare type SessionStatus = 'success' | 'unsynced';

interface Context {
  syncing: boolean;
  syncSession: () => void;
  sessionStatus: SessionStatus;
  setSyncing: (bool: boolean) => void;
}

export const SyncSessionContext = createContext<Context>({
  syncing: false,
  syncSession: async () => console.log(),
  sessionStatus: 'success',
  setSyncing: () => null,
});

export const useSyncSession = (): Context => useContext(SyncSessionContext);
