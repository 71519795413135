import {Invoice} from '@emporos/api-enterprise';
import {RouteComponentProps} from '@reach/router';
import assert from 'assert';
import {memo} from 'react';
import {
  AnalyticType,
  OfflineCustomer,
  OfflineInvoice,
  OfflineSynced,
  useAnalyticsProvider,
  useGlobalData,
  useInvoice,
  withRootPage,
} from '../../../../../';
import {CompleteTransaction} from './';

function calculateFirstFillPercent(invoice: Invoice) {
  const {items, customer} = invoice;
  const totalFirstFillRx = (customer as OfflineCustomer)?.prescriptions?.filter(
    // Checking solely on fill will not work once we are fully integrated with Epic
    ({fill}) => fill === '00',
  );
  const firstFillPurchased = items.filter(({fill, rx}) => rx && fill === '00');
  if (totalFirstFillRx?.length) {
    return (firstFillPurchased.length / totalFirstFillRx.length) * 100;
  }
}

export const CompleteTransactionIntegration = memo(
  withRootPage(
    ({navigate}: RouteComponentProps): JSX.Element => {
      const {track} = useAnalyticsProvider();
      const {invoice, updateInvoice} = useInvoice();
      const {taxGroupsResult} = useGlobalData();
      const {NODE_ENV} = process.env;

      assert(
        navigate,
        '<CompleteTransaction /> must have a `navigate` prop.' +
          String(NODE_ENV) ===
          'production'
          ? ''
          : ' This likely means that you need to have it as a direct child of a <Router />',
      );
      assert(invoice, 'Missing invoice');

      return (
        <CompleteTransaction
          onBack={() => navigate('../receipts')}
          onCustomerInfo={() => navigate('customer-info')}
          onComplete={() => {
            assert(taxGroupsResult && taxGroupsResult.data, 'Missing taxes');
            updateInvoice(
              {
                isCompleted: true,
                isSynced: true,
              } as Partial<OfflineInvoice> & OfflineSynced,
              true,
            );
            track(AnalyticType.OrderCompleted, {
              total: invoice.totalSale,
              firstFillPickupPercent: calculateFirstFillPercent(invoice),
              products: invoice.items
                .filter(({rx}) => !rx)
                .map(({description, itemNumber, price, quantity}) => {
                  return {
                    category: 'OTC',
                    name: description || '',
                    price,
                    productId: itemNumber || '',
                    quantity,
                  };
                }),
              saleDuration: +new Date() - +invoice.saleDate,
            });

            setTimeout(() => navigate('/sales'), 400);
          }}
        />
      );
    },
  ),
);
