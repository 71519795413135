import {
  InvoicesApi,
  Invoice,
  InvoicePayment,
  SignatureImage,
  Configuration,
  HTTPHeaders,
  InvoiceStatusEnum,
  SignatureImagesApi,
  InvoicePaymentsApi,
  SignatureImageRequest,
  InvoicePaymentRequest,
} from '@emporos/api-enterprise';
import {ConvertInvoiceToRequest} from '@emporos/api-enterprise/src/converters/InvoiceConverter';
import {InvoiceConsolidate} from '@emporos/pos/src';
import {InvoicePaymentConsolidate} from '../api';
import {DIFactory} from '../DIFactory';
import {
  IInvoiceDatabaseAccess,
  IInvoicePaymentsDatabaseAccess,
} from '../localDb/IDatabaseAccess';
import {ApiVersion} from './ApiVersion';
import {IInvoiceService} from './IInvoiceService';

const {CLIENT_API_URL} = process.env;

// instantiate with DIFactory.getInvoiceService
export class InvoiceService implements IInvoiceService {
  private _sessionId!: string;
  private _token!: string;
  private _version: ApiVersion = '1.5';
  private _headers!: HTTPHeaders;
  private _client!: Configuration;
  private _invoiceApi!: InvoicesApi;
  private _signaturaApi!: SignatureImagesApi;
  private _invoicePaymentsApi!: InvoicePaymentsApi;
  private _invoiceDb!: IInvoiceDatabaseAccess;
  private _invoicePaymentDb!: IInvoicePaymentsDatabaseAccess;

  initialize = (sessionId: string, token: string): void => {
    this._sessionId = sessionId;
    this._token = token;
    this._headers = {
      'Content-Type': `application/json;v=${this._version}`,
      Authorization: `Bearer ${this._token}`,
    };
    this._client = new Configuration({
      basePath: CLIENT_API_URL,
      headers: this._headers,
    });
    this._invoiceApi = new InvoicesApi(this._client);
    this._signaturaApi = new SignatureImagesApi(this._client);
    this._invoicePaymentsApi = new InvoicePaymentsApi(this._client);
    this._invoiceDb = DIFactory.getInvoiceDatabaseAccess(this._sessionId);
    this._invoicePaymentDb = DIFactory.getInvoicePaymentsDatabaseAccess(
      this._sessionId,
    );
  };

  syncInvoices = async (_currentInvoiceId: string): Promise<void> => {
    console.log(
      '%cSync Start!-' + _currentInvoiceId,
      'background-color:#05D5C5; color: black',
    );

    const invoice = await this._invoiceDb.get(_currentInvoiceId);

    console.log('Invoice in local db: ', invoice);

    switch (invoice.status) {
      case InvoiceStatusEnum.Active: {
        if (invoice.serverTransactionID == 0) {
          //Action new invoice
          console.log('Action new invoice');
          await this.createInvoice(invoice as Invoice);
          return;
        }
        if (
          invoice.serverTransactionID > 0 &&
          (invoice as InvoiceConsolidate).isDeleted
        ) {
          //Action Delete invoice
          console.log('Action Delete invoice');
          await this.deleteInvoice(invoice as Invoice);
          return;
        }
        if (
          invoice.serverTransactionID > 0 &&
          !(invoice as InvoiceConsolidate).isDeleted
        ) {
          if (invoice.serverTransactionID > 0 && invoice.payments.length > 0) {
            //check payments
            for (let payment of invoice.payments) {
              const paymetinDb = await this._invoicePaymentDb.get(
                payment.invoicePaymentId,
              );

              if (
                (paymetinDb.invoicePaymentStatus == null ||
                  paymetinDb.invoicePaymentStatus == undefined) &&
                (payment.invoicePaymentStatus == null ||
                  payment.invoicePaymentStatus == undefined)
              ) {
                //Action new payment
                console.log('Action new payment');
                const paymentresponse = await this.postInvoicePayment(payment);
                payment = paymentresponse;
                await this._invoicePaymentDb.add(paymentresponse);
              } else {
                //Action update payment
                console.log('Action update payment for cancel');
                if ((payment as InvoicePaymentConsolidate).isDeleted == true) {
                  if (paymetinDb.isDeleted) {
                    // exists in the db and was deleted before
                    console.log('payment already deleted');
                  } else {
                    const paymentresponse = await this.deleteInvoicePayment(
                      payment,
                    );
                    payment = paymentresponse;
                    (paymentresponse as InvoicePaymentConsolidate).isDeleted = true;
                    await this._invoicePaymentDb.update(
                      paymentresponse as InvoicePaymentConsolidate,
                    );
                  }
                }
              }
            }
            await this._invoiceDb.update(invoice);
          }
        }

        if (invoice.signatureImage?.signatureImageId && !invoice.isCompleted) {
          //Action Signature
          try {
            await this.putSignatureImage(invoice.signatureImage, invoice);
          } catch (error) {
            console.log(error);
          }
        }

        if (invoice.isCompleted) {
          //Action complete Invoice
          console.log('Action Complete Invoice');

          await this.patchInvoice(invoice);
          return;
        } else {
          //Action Update Invoice
          console.log('Action Update Invoice');
          await this.putInvoice(invoice);
          return;
        }
      }
      case InvoiceStatusEnum.Complete:
        console.log('Invoice is complete');
      case InvoiceStatusEnum.Error:
        alert(
          'Invoice ' +
            invoice.invoiceId.toString() +
            ' have an error please contact support, to verify the status',
        );
      case InvoiceStatusEnum.Accepted:
        invoice.isCompleted = true;
        invoice.status = InvoiceStatusEnum.Complete;
        await this._invoiceDb.update(invoice);
        await this.patchInvoice(invoice);
        return;

      //return await this.putInvoice(invoice);
      case InvoiceStatusEnum.Deleted:
        if (
          invoice.serverTransactionID > 0 &&
          (invoice as InvoiceConsolidate).isDeleted
        ) {
          //Action Delete invoice
          console.log('Action Delete invoice');
          await this.deleteInvoice(invoice as Invoice);
          return;
        }
      default:
        throw new Error('Invalid invoice status');
    }
  };

  createInvoice = async (invoice: Invoice): Promise<Invoice> => {
    const response = await this._invoiceApi.clientCacheSessionsSessionIdInvoicesPost(
      {
        sessionId: this._sessionId,
        invoiceRequest: ConvertInvoiceToRequest(invoice),
      },
    );

    (response as InvoiceConsolidate).isSynced = true;
    (response as InvoiceConsolidate).isDeleted = false;
    (response as InvoiceConsolidate).isCompleted = false;

    await this._invoiceDb.update(response as InvoiceConsolidate);

    return response;
  };

  putInvoice = async (invoice: InvoiceConsolidate): Promise<Invoice> => {
    const response = await this._invoiceApi.clientCacheSessionsSessionIdInvoicesPut(
      {
        sessionId: this._sessionId,
        invoiceRequest: ConvertInvoiceToRequest(invoice),
      },
    );

    (response as InvoiceConsolidate).isSynced = invoice.isSynced;
    (response as InvoiceConsolidate).isDeleted = invoice.isDeleted;
    (response as InvoiceConsolidate).isCompleted = invoice.isCompleted;

    await this._invoiceDb.update(response as InvoiceConsolidate);

    //Todo update back database and UI
    console.log('Put Invoice Response', response);
    return response;
  };

  patchInvoice = async (invoice: InvoiceConsolidate): Promise<Invoice> => {
    const response = await this._invoiceApi.clientCacheSessionsSessionIdInvoicesInvoiceIdCompletePatch(
      {
        sessionId: this._sessionId,
        invoiceId: invoice.invoiceId,
      },
    );

    (response as InvoiceConsolidate).isSynced = invoice.isSynced;
    (response as InvoiceConsolidate).isDeleted = invoice.isDeleted;
    (response as InvoiceConsolidate).isCompleted = invoice.isCompleted;

    await this._invoiceDb.update(response as InvoiceConsolidate);

    //Todo update back database and UI
    console.log('Patch Complete Invoice Response', response);

    return response;
  };

  postInvoice = async (invoice: Invoice): Promise<Invoice> => {
    return invoice;
  };

  putInvoicePayment = async (
    payment: InvoicePayment,
  ): Promise<InvoicePayment> => {
    const response = await this._invoicePaymentsApi.clientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsPut(
      {
        sessionId: this._sessionId,
        invoiceId: payment.invoiceId,
        invoicePaymentRequest: payment as InvoicePaymentRequest,
      },
    );

    return response;
  };

  postInvoicePayment = async (
    payment: InvoicePayment,
  ): Promise<InvoicePayment> => {
    const response = await this._invoicePaymentsApi.clientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsPost(
      {
        sessionId: this._sessionId,
        invoiceId: payment.invoiceId,
        invoicePaymentRequest: payment as InvoicePaymentRequest,
      },
    );

    console.log('Post Invoice Payment Response', response);
    return response;
  };

  putSignatureImage = async (
    signatureImage: SignatureImage,
    invoice: InvoiceConsolidate,
  ): Promise<SignatureImage> => {
    const response = await this._signaturaApi.clientCacheSignaturesImagesPut({
      signatureImageRequest: signatureImage as SignatureImageRequest,
    });

    invoice.signatureImage = response;

    await this._invoiceDb.update(invoice);

    return response;
  };

  deleteInvoice = async (invoice: Invoice): Promise<void> => {
    await this._invoiceApi.clientCacheSessionsSessionIdInvoicesInvoiceIdCancelPatch(
      {
        sessionId: this._sessionId,
        invoiceId: invoice.invoiceId,
      },
    );

    await this._invoiceDb.delete(invoice.invoiceId);
  };

  deleteInvoicePayment = async (
    invoicePayment: InvoicePayment,
  ): Promise<InvoicePayment> => {
    const response = await this._invoicePaymentsApi.clientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsInvoicePaymentIdDelete(
      {
        sessionId: this._sessionId,
        invoiceId: invoicePayment.invoiceId,
        invoicePaymentId: invoicePayment.invoicePaymentId,
      },
    );

    console.log('Post Invoice Payment Response', response);
    return response;
  };
}
