import {RouteComponentProps} from '@reach/router';
import assert from 'assert';
import {memo} from 'react';
import {
  AnalyticType,
  Invoice,
  OfflineSynced,
  useAnalyticsProvider,
  useInvoice,
  withChildPage,
} from '../../../../../';
import {Receipts} from './';

export interface EmailForm {
  email: string;
}

function ReceiptsIntegrationComponent({navigate}: RouteComponentProps) {
  const {track} = useAnalyticsProvider();
  const {invoice, updateInvoice} = useInvoice();
  const {NODE_ENV} = process.env;

  assert(
    navigate,
    '<ReceiptsIntegration /> must have a `navigate` prop.' +
      String(NODE_ENV) ===
      'production'
      ? ''
      : ' This likely means that you need to have it as a direct child of a <Router />',
  );
  assert(invoice, 'Missing invoice');

  const trackReceiptType = (receiptType: string) =>
    track(AnalyticType.ReceiptType, {receiptType});

  const onNext = () => {
    trackReceiptType('none');
    navigate('../complete');
  };
  const onSubmit = (data: EmailForm) => {
    trackReceiptType('email');
    updateInvoice({emailReceipt: data.email, isSynced: false} as Partial<
      Invoice
    > &
      OfflineSynced);

    return navigate('../complete');
  };
  return (
    <Receipts
      invoice={invoice}
      onPrint={() => {
        trackReceiptType('printed');
        return navigate('../complete');
      }}
      onNext={onNext}
      onSubmit={onSubmit}
      navigate={navigate}
    />
  );
}

export const ReceiptsIntegration = memo(
  withChildPage(ReceiptsIntegrationComponent),
);
