import {CustomerSearchMatch} from '@emporos/api-enterprise';
import {Redirect, RouteComponentProps} from '@reach/router';
import {memo, useCallback, useEffect} from 'react';
import {
  activeInvoiceCustomersIds,
  AnalyticType,
  ApiLogTypes,
  DEFAULT_PMS,
  mapInvoiceCustomer,
  SearchParams,
  useAnalyticsProvider,
  useCustomerSearch,
  useCustomerWillCall,
  useGlobalData,
  useLog,
  useNetworkAvailable,
  useSession,
  useTransactionsState,
  withChildPage,
} from '../../../';
import {useApi} from '../../../contexts/ApiProvider';
import {CustomerSearch, parseDateNoTime} from './';

interface Props {
  addToAr?: boolean;
}

function AddCustomerComponent(props: Props & RouteComponentProps) {
  const api = useApi();
  const {track} = useAnalyticsProvider();
  const {setCustomer} = useCustomerSearch();
  const {logApi} = useLog();
  const online = useNetworkAvailable();
  const {session, currentInvoiceId} = useTransactionsState();
  const {pendingInvoices} = useSession();
  const {pmsName, setSkipWillCall} = useGlobalData();
  const {addToAr, navigate, location} = props;
  const {siteId, stationId} = session;
  const isMcKesson = pmsName === DEFAULT_PMS;

  if (!currentInvoiceId) {
    return <Redirect to="/sales" noThrow />;
  }

  const {run: postSearchCustomers} = api.PostSearchCustomers();
  const {run: getCustomerById} = api.GetCustomer();
  const {run: putWillCall, customer} = useCustomerWillCall();
  const onSearch = useCallback(
    async ({name, dob, mrn}: SearchParams) => {
      logApi(ApiLogTypes.CustomerPending);

      return postSearchCustomers({
        siteId: siteId,
        stationId: stationId,
        customerSearchCriteria: {
          name: name || undefined,
          dob: dob ? parseDateNoTime(dob) : undefined,
          mrn: mrn || undefined,
        },
      });
    },
    [logApi, postSearchCustomers, siteId, stationId],
  );
  const onCancel = useCallback(() => navigate && navigate('../'), [navigate]);
  const onWillCallFailed = useCallback(
    async ({id}: CustomerSearchMatch) => {
      const customerInfo = await getCustomerById({
        customerId: id,
      });
      if (customerInfo?.data) {
        setCustomer(mapInvoiceCustomer(customerInfo.data));
        navigate &&
          navigate('../', {
            replace: true,
          });
      }
    },
    [getCustomerById],
  );
  const skipWillCallCustomer = useCallback(
    async ({id}: CustomerSearchMatch, skipWillCall: boolean) => {
      const customerInfo = await getCustomerById({
        customerId: id,
      });
      if (customerInfo?.data) {
        setCustomer(mapInvoiceCustomer(customerInfo.data));
        setSkipWillCall(skipWillCall);
        navigate &&
          navigate('../', {
            replace: true,
          });
      }
    },
    [getCustomerById],
  );
  const tryLoadCustomer = useCallback(
    async (match: CustomerSearchMatch, skipWillCall: boolean) => {
      if (!match.customerCode && !match.mrn) {
        throw new Error(
          'Failed to load customer: customer code is required to perform will call',
        );
      }
      if (skipWillCall) {
        return skipWillCallCustomer(match, skipWillCall);
      } else {
        return putWillCall({
          customerCode: match.customerCode,
          mrn: match.mrn,
        }).catch(() => onWillCallFailed(match));
      }
    },
    [putWillCall],
  );

  useEffect(() => {
    if (customer) {
      setCustomer(customer);
      navigate &&
        navigate('../', {
          replace: true,
        });
      track(AnalyticType.CustomerSearch, {page: location?.pathname || ''});
    }
  }, [customer]);

  return (
    <CustomerSearch
      addToAr={addToAr}
      onSearch={onSearch}
      onAddCustomer={tryLoadCustomer}
      onCancel={onCancel}
      online={online}
      activeInvoiceCustomerIds={activeInvoiceCustomersIds(pendingInvoices)}
      isMcKesson={isMcKesson}
    />
  );
}

export const AddCustomer = memo(withChildPage(AddCustomerComponent));
