import {
  Button,
  ButtonShape,
  FooterGroup,
  Gutter,
  Header,
  ScrollContainer,
  SegmentSlider,
  Select,
  ButtonSize as Size,
  Stack,
  Text,
  TextInput,
  TextVariant as Variant,
  Illustration,
} from '@emporos/components';
import {yupResolver} from '@hookform/resolvers/yup';
import {NavigateFn} from '@reach/router';
import moment from 'moment';
import {memo, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import * as yup from 'yup';
import {Invoice, useBetaFeatures} from '../../../../../';
import {EmailForm} from './';

interface Props {
  invoice: Invoice;
  navigate: NavigateFn;
  onPrint(): void;
  onNext(): void;
  onSubmit(data: EmailForm): void;
}

const schema = yup.object().shape({email: yup.string().email().required()});

const ReceiptsChild = ({
  invoice,
  onNext,
  onPrint,
  onSubmit,
  navigate,
}: Props): JSX.Element => {
  const [active, setActive] = useState(0);
  const {register, watch, control, handleSubmit, formState} = useForm<
    EmailForm
  >({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });
  const printingEnabled = useBetaFeatures().printing;
  const availableItems = printingEnabled
    ? ['Email', 'Print', 'None']
    : ['Email', 'None'];
  const EMAIL_INDEX = 0;
  const PRINT_INDEX = printingEnabled ? 1 : -1;
  const {serverTransactionID, saleDate} = invoice;

  const OtherReceiptType = (activeTab: number) => {
    return activeTab === PRINT_INDEX ? (
      <Stack style={{height: '100%', width: '100%', minHeight: 0}}>
        <ScrollContainer>
          <Stack
            gutter={Gutter.None}
            align="center"
            style={{flex: 1, marginTop: 36}}
          >
            {serverTransactionID && (
              <Stack justify="center" align="center" gutter={Gutter.XS}>
                <Text variant={Variant.T2}>#{serverTransactionID}</Text>
                <Text variant={Variant.Main}>
                  {moment(saleDate).format('MM/DD/YYYY - hh:mmA')}
                </Text>
              </Stack>
            )}
            <Illustration illustration="Receipt" />
            <Select
              label="Printer"
              options={['Front Desk']}
              value={null}
              onChangeValue={() => console.log('Unimplemented')}
              style={{width: 372, flex: 0}}
            />
          </Stack>
        </ScrollContainer>
        <FooterGroup>
          <Button onClick={onPrint} flex>
            Print Receipt
          </Button>
        </FooterGroup>
      </Stack>
    ) : (
      <Stack
        style={{height: '100%', width: '100%', minHeight: 0}}
        data-testid="no-receipt"
      >
        <ScrollContainer>
          <Stack
            gutter={Gutter.None}
            align="center"
            style={{flex: 1, marginTop: 36}}
          >
            {serverTransactionID && (
              <Stack justify="center" align="center" gutter={Gutter.XS}>
                <Text variant={Variant.T2}>#{serverTransactionID}</Text>
                <Text variant={Variant.Main}>
                  {moment(saleDate).format('MM/DD/YYYY - hh:mmA')}
                </Text>
              </Stack>
            )}
            <Illustration illustration="TaskNotDone" />
            <Text variant={Variant.T3}>No receipt</Text>
          </Stack>
        </ScrollContainer>
        <FooterGroup>
          <Button onClick={onNext} flex>
            Next
          </Button>
        </FooterGroup>
      </Stack>
    );
  };

  return (
    <Stack gutter={Gutter.L} align="center" style={{flex: 1}}>
      <Header title="Receipt Delivery" style={{alignSelf: 'stretch'}}>
        <ButtonShape
          size={Size.Small}
          icon="User"
          data-testid="customer-info-btn"
          onClick={() => navigate('customer-info')}
        />
      </Header>

      <SegmentSlider
        onSelectIndex={setActive}
        active={active}
        items={availableItems}
      />

      {active === EMAIL_INDEX ? (
        <Stack
          style={{height: '100%', width: '100%', minHeight: 0}}
          as="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <ScrollContainer>
            <Stack
              align="center"
              gutter={Gutter.None}
              style={{flex: 1, marginTop: 36}}
            >
              {serverTransactionID && (
                <Stack justify="center" align="center" gutter={Gutter.XS}>
                  <Text variant={Variant.T2}>#{serverTransactionID}</Text>
                  <Text variant={Variant.Main}>
                    {moment(saleDate).format('MM/DD/YYYY - hh:mmA')}
                  </Text>
                </Stack>
              )}
              <Illustration illustration="NoEmails" />
              <Controller
                defaultValue=""
                as={
                  <TextInput
                    autoComplete="off"
                    label="Email"
                    value={watch('email')}
                    ref={register}
                    style={{width: 422}}
                  />
                }
                name="email"
                control={control}
              />
            </Stack>
          </ScrollContainer>

          <FooterGroup>
            <Button type="submit" disabled={!formState.isValid} flex>
              Send Email
            </Button>
          </FooterGroup>
        </Stack>
      ) : (
        OtherReceiptType(active)
      )}
    </Stack>
  );
};

export const Receipts = memo(ReceiptsChild);
